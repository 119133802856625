<template>
    <div>
        <Navbar page="Titulares" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-4">
                                <label for="name" class="block text-sm font-medium">Primeiro e último nome</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" placeholder="José Maria" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-3">
                                <label for="cpf_cnpj" class="block text-sm font-medium">3 últimos dígitos do CPF</label>
                                <input v-model="form.cpf_cnpj" type="number" name="cpf_cnpj" id="cpf_cnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="perfil_titular" class="block text-sm font-medium">Tipo de Títular</label>
                                <select required v-model="form.tipoTitular" name="tipoTitular" id="tipoTitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="tipo in tiposTitulares" :key="tipo._id" :value="tipo._id">{{tipo.tipo}}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="email" class="block text-sm font-medium">Email</label>
                                <input v-model="form.email" type="text" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                                <input v-model="form.telefone" type="text" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="data_relacionamento" class="block text-sm font-medium">Data Relacionamento</label>
                                <datepicker v-model="form.data_relacionamento" :language="ptBR" name="data_relacionamento" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div class="col-span-12 md:col-span-12">
                                <label class="block text-sm font-medium"> Tipos de dados tratados</label>
                                <multiselect v-model="form.dadostratados" :options="dados" :multiple="true" :close-on-select="false" @input="onSelect" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            ptBR: ptBR,
            route: 'titulares',
            dados: [],
            tiposTitulares: [],
            form: {
                nome: '',
                cpf_cnpj: '',
                email: '',
                telefone: '',
                perfil_titular: '',
                data_relacionamento: null,
                dadostratados: [],
                tipoTitular: null,
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        customLabel ({ nome, sensivel, critico })  {
            return `${nome}${sensivel ? ' - DADO SENSÍVEL': ''}${critico ? ' - DADO CRÍTICO': ''}`
        },
        onSelect () {
            setTimeout(function() {
                const tags = document.getElementsByClassName('multiselect__tag');
                for(let i = 0; i < tags.length; i++){
                    const tag = tags[i];
                    if(tag.children[0].innerHTML.match('CRÍTICO')){
                        tag.style.backgroundColor = "#f0ad4e";
                    }
                    if(tag.children[0].innerHTML.match('SENSÍVEL')){
                        tag.style.backgroundColor = "#d9534f";
                    }
                }
            }, 500)
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listDados = await this.$http.post(`/v1/dados/list`, { all: true});
        this.dados = listDados.data.data;

        const reqtipos = await this.$http.post('/v1/tipotitulares/list', { all: true});
        this.tiposTitulares = reqtipos.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            this.onSelect();
        }
    },
}
</script>